if (document.querySelector(".notions__box")) {
    const boxHeight = document.querySelector(".notions__box").offsetHeight
    document.querySelector(".notions__star").style.top = `${boxHeight}px`
    window.addEventListener("resize", function () {
        const boxHeight = document.querySelector(".notions__box").offsetHeight
        document.querySelector(".notions__star").style.top = `${boxHeight}px`
    })
}

if (document.querySelector(".main article")) {
    if (document.querySelector(".main article .page-hero") && !document.getElementById('to-scroll')) {
        const parent = document.querySelector(".main article")
        const secendChild = parent.getElementsByTagName('section')[1]
        secendChild.setAttribute('id', 'to-scroll')
    }
}


document.addEventListener('facetwp-loaded', function () {
    setTimeout(() => {
        Array.from(document.getElementsByClassName("fs-options")).forEach((option) => {
            const firstOption = option.querySelector(".fs-option")
            firstOption.querySelector(".fs-option-label").innerHTML = "All"
        })
    }, "500")
})


if (document.querySelector(".header__search__button")) {

    document.querySelector(".header__search__button").addEventListener("click", (e) => {
        if (document.querySelector(".header__search").classList.contains("active")) {
            document.getElementById('searchsubmit').click();
        } else {
            document.querySelector(".header__search").classList.toggle("active")
        }
    });


    document.querySelector('.header__search__button').addEventListener('click', () => {
        document.querySelector('.header__search').classList.add('active');
        document.body.classList.add('active');
    });

    document.querySelector('.header__search__close').addEventListener('click', () => {
        document.querySelector('.header__search').classList.remove('active');
        document.querySelector('.header__search__form').classList.remove('active');
    });


    document.addEventListener('click', (e) => {
        if (document.body.classList.contains('active')) {
            const outSide = !e.target.closest('.header__search');
            const search = document.querySelector('.header__search');

            if (outSide) {
                search.classList.remove('active');
            }
        }
    });
}

